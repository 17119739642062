import { h, render } from 'preact';
import { FlowButton } from './flowButton.component';
import { ButtonLayout } from '../containers/buttonLayout.container';
import { ViewHeadline } from './viewHeadline.component';
import { StepCounter } from './stepCounter.component';

export function AtFault() {
  const pageHeader = {
    headline: 'Was the accident your fault?'
  };

  const progressChild = (
    <StepCounter
      currentStep={3}
      totalSteps={11} />
  );

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const modelProp = 'atFault';

  const nextUrl = 'commercial-vehicle';

  const btnData = [
    {
      to: nextUrl,
      modelVal: 'Yes',
      modelProp,
      buttonText: 'Yes',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 'No',
      modelProp,
      buttonText: 'No',
      component: FlowButton
    }
  ];

  return (
    <ButtonLayout
      headlineChild={headlineChild}
      progressChild={progressChild}
      btnData={btnData}/>
  );
}
