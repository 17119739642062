export const initFormData = {
  API_Key: 'fc149956-e054-4cea-906b-812944034533',
  GUID: '',
  click_id: '',
  email: '',
  inParams: '',
  kw: '',
  mediacid: '',
  mediatag: '',
  offer: '',
  phone: '',
  r: '',
  s1: '',
  s2: '',
  s3: '',
  s4: '',
  s5: '',
  sub_id: '',
  tpl: ''
};
