import { h, render } from 'preact';
import { StepCounter } from './stepCounter.component';
import { ViewHeadline } from './viewHeadline.component';
import { FlowButton } from './flowButton.component';
import { ButtonLayout } from '../containers/buttonLayout.container';

export function IncidentDate() {
  const pageHeader = {
    headline: 'When did the accident occur?'
  };

  const progressChild = (
    <StepCounter
      currentStep={2}
      totalSteps={11}/>
  );

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const modelProp = 'X-incidentDaysAgo';
  const nextUrl = 'at-fault';

  const btnData = [
    {
      to: nextUrl,
      modelVal: '0-30',
      modelProp,
      buttonText: 'Within Last month',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: '30-90',
      modelProp,
      buttonText: 'Within 3 months',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: '90-180',
      modelProp,
      buttonText: 'Within 6 months',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: '180-364',
      modelProp,
      buttonText: '6 months to a year',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: '365',
      modelProp,
      buttonText: 'Over a Year Ago',
      component: FlowButton
    }
  ];

  return (
    <ButtonLayout
      headlineChild={headlineChild}
      progressChild={progressChild}
      btnData={btnData}/>
  );
}
