import { h, render } from 'preact';

export function ViewHeader(props) {
  return (
    <div className="view-header">
      {props.progress}
      {props.headline}
    </div>
  )
}
