import { cakePost } from './cakePost.utility';

export function leadPost(data) {
  cakePost(data)
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
      throw new Error(err);
    })
}
