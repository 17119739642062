export function showSpinner() {
  document.querySelector('.modal-spinner').classList.add('active');
}

export function closeSpinner() {
  document.querySelector('.modal-spinner').classList.remove('active');
}

export function showConfirmationModal(userFirstName) {
  document.getElementById('userFirstName').insertAdjacentHTML('beforeend', userFirstName);
  document.querySelector('#confirmation-modal-wrapper .modal').classList.add('show');
  document.querySelector('body').classList.add('.modal-open');
  callCenterMessaging()
}

function callCenterMessaging() {
  const now = new Date();
  const currentHour = now.getHours();
  const closedHours = (currentHour < 7) || (currentHour > 19);

  if (closedHours) {
    document.querySelector('.closed-msg').classList.remove('d-none');
    document.querySelector('.open-msg').classList.add('d-none');
  }
}
