import * as yup from 'yup';

const ERROR_MSGS = {
  COMMENTS: 'Please provide a detailed description of the incident'
}

const commentsFormShape = {
  comments: yup.string().min(40).required(ERROR_MSGS.STATE)
};

export const commentsFormSchema = yup.object().shape(commentsFormShape);
