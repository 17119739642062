import * as yup from 'yup';

const ERROR_MSGS = {
  STATE: 'Please select a state'
}

const accidentStateFormShape = {
  accidentState: yup.string().required(ERROR_MSGS.STATE)
};

export const accidentStateFormSchema = yup.object().shape(accidentStateFormShape);
