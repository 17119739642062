import { h, render } from 'preact';
import { Link } from 'react-router-dom';

// TODO: JSDoc
export function ButtonGroup(props) {
  return (
    <div className="d-grid col-12">
      { props.btnData.map(button => <Link {...button} />) }
    </div>
  );
}
