import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { GlobalContext } from '../GlobalState';

export const FlowButton = (props) => {
  const { updateModelProperty } = useContext(GlobalContext);

  const onClickHandler = (ev) => {
    updateModelProperty({
      [props.modelProp]: props.modelVal
    });
  }

  return (
    <a {...props}
       onClick={onClickHandler}
       className="flow-btn btn btn-outline-dark btn-default">{props.buttonText}</a>
  );
}
