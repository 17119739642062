import * as yup from 'yup';

const ERROR_MSGS = {
  FIRST_NAME: 'Please enter your first name',
  LAST_NAME: 'Please enter your last name',
  PHONE: 'Please enter a valid phone number',
  ADDRESS: 'Please enter a valid address',
  ZIP_CODE: 'Please Enter a Valid Zip Code',
  EMAIL: 'Please enter a valid e-mail address'
}

const verifyInfoFormShape = {
  firstName: yup.string().required(ERROR_MSGS.FIRST_NAME),
  lastName: yup.string().required(ERROR_MSGS.LAST_NAME),
  phone: yup.string().required(ERROR_MSGS.PHONE),
  address: yup.string().required(ERROR_MSGS.ADDRESS),
  zip: yup.string().required(ERROR_MSGS.ZIP_CODE),
  email: yup.string().email().required(ERROR_MSGS.EMAIL)
};

export const verifyInfoFormSchema = yup.object().shape(verifyInfoFormShape);
