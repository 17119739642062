import { h, render } from 'preact';
import { FlowButton } from './flowButton.component';
import { ButtonLayout } from '../containers/buttonLayout.container';
import { ViewHeadline } from './viewHeadline.component';
import { StepCounter } from './stepCounter.component';

export function CommercialVehicle() {
  const pageHeader = {
    headline: 'Was the other driver driving a commercial vehicle?',
    subheadline: '(Commercial Vehicles include 18-wheelers, semi-trucks, Uber/Lyft, or any vehicle being driven for work purposes)'
  };

  const progressChild = (
    <StepCounter
      currentStep={4}
      totalSteps={11} />
  );

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const nextUrl = 'injured';
  const modelProp = 'commercial'

  const btnData = [
    {
      to: nextUrl,
      modelVal: 'Yes',
      modelProp,
      buttonText: 'Yes',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 'No',
      modelProp,
      buttonText: 'No',
      component: FlowButton
    }
  ];

  return (
    <ButtonLayout
      headlineChild={headlineChild}
      progressChild={progressChild}
      btnData={btnData} />
  );
}
