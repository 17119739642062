import { h, render, Fragment } from 'preact';

export function ViewHeadline(props) {
  return (
    <Fragment>
      <h3>{props.headline}</h3>
      {props.subheading && (
        <p>{props.subheading}</p>
      )}
    </Fragment>
  )
}
