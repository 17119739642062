import { h, render } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { ViewHeadline } from './viewHeadline.component';
import { ViewHeader } from './viewHeader.component';
import { StepCounter } from './stepCounter.component';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../GlobalState';
import { commentsFormSchema } from '../validators/comments.validator';

export function Comments() {
  const { updateModelProperty } = useContext(GlobalContext);

  const initComments = {
    comments: ''
  };

  const [comments, setComments] = useState(initComments);
  const pageHeader = {
    headline: 'Please describe the details of the incident.  Please be as thorough as possible.'
  }

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const progressChild = (
    <StepCounter
      currentStep={9}
      totalSteps={11}/>
  );

  const nextUrl = 'contact'
  const button = {
    to: nextUrl,
    buttonText: 'Yes'
  };

  const onBlurHandler = (ev) => {
    setComments({
      [ev.target.name]: ev.target.value
    });
    console.log(comments);
  }

  const onClickHandler = (ev) => {
    const isValidInput = async (schema) => {
      try {
        const valid = await schema.validateSync(
          {
            ...comments
          }, { abortEarly: false })

        if (valid) {
          console.log('VALID');
          // update model here if the form entries are valid
          updateModelProperty({
            ...comments
          });
        }
      } catch (error) {
        ev.preventDefault();
        // Debugging
        console.log(Array.isArray(error.inner), '\n\n\nError Inner:', error.inner);

        // DISPLAY ERROR MESSAGES

        // using path, update add the error classes
        error.inner.forEach(err => {
          const errorFieldId = err.path;
          const errorField = document.getElementById(errorFieldId);
          const errorMessage = document.querySelector('#comments ~ .invalid-feedback');

          // Debugging
          console.log(err.path, err.message);
          console.log(errorField, errorMessage, errorFieldId + ' ~ .invalid-feedback');

          errorField.classList.add('is-invalid');

          if (!errorMessage.innerHTML.length) {
            errorMessage.insertAdjacentHTML('beforeend', err.message)
          }
        });
      }
    }
    isValidInput(commentsFormSchema);
  }

  const onFocusHandler = (ev) => {
    // remove the error messages if they exist
    console.log(ev.target);
    const focusField = ev.target;
    focusField.classList.remove('is-invalid');
  }

  return (
    <div className="view-bg">
      <CSSTransition
        in={true}
        appear
        enter
        exit
        timeout={0}
        classNames={'flow-view'}>

        <div className="col-12 col-sm-10 offset-sm-1">
          <ViewHeader
            headline={headlineChild}
            progress={progressChild}/>

          <form className={'mb-4'} action="">
            <textarea name="comments" id="comments" cols="30" rows="10"
                      className={'form-control'}
                      placeholder={'Describe the incident here.'}
                      onBlur={onBlurHandler}
                      onFocus={onFocusHandler}
                      minLength="40" required></textarea>
            <p className="invalid-feedback"></p>
          </form>

          <div className="next-btn-wrap col-12 col-sm-6 offset-sm-3 text-center">
            <Link {...button}
              className={'flow-btn btn btn-outline-dark btn-default'}
              onClick={onClickHandler}>Next</Link>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
