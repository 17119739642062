import { h, render } from 'preact';
import { FlowButton } from './flowButton.component';
import { ButtonLayout } from '../containers/buttonLayout.container';
import { ViewHeadline } from './viewHeadline.component';
import { StepCounter } from './stepCounter.component';

export function MedicalTreatment() {
  const pageHeader = {
    headline: 'Did you, or do you plan on receiving medical treatment?'
  };

  const progressChild = (
    <StepCounter
      currentStep={6}
      totalSteps={11}/>
  );

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const modelProp = 'medical';
  const nextUrl = 'est-medical-bills';

  const btnData = [
    {
      to: nextUrl,
      modelVal: 'Yes',
      modelProp,
      buttonText: 'Yes',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 'No',
      modelProp,
      buttonText: 'No',
      component: FlowButton
    }
  ];

  return (
    <ButtonLayout
      headlineChild={headlineChild}
      progressChild={progressChild}
      btnData={btnData}/>
  );
}
