import { h, render } from 'preact';
import { CSSTransition } from 'react-transition-group';
import { ViewHeader } from '../components/viewHeader.component';
import { ButtonGroup } from '../components/ButtonGroup.component';

export function ButtonLayout(props) {
  return (
    <div className="view-bg">
      <CSSTransition
        in={true}
        appear
        enter
        exit
        timeout={0}
        classNames={'flow-view'}>

        <div className="col-12 col-sm-10 offset-sm-1">
          <ViewHeader
            headline={props.headlineChild}
            progress={props.progressChild}/>

          <ButtonGroup btnData={props.btnData}/>
        </div>
      </CSSTransition>
    </div>
  )
}
