export async function cakePost(data) {
  const cakePostData = JSON.stringify(data);
  console.log(cakePostData);

  const cakePostEndpointUrl = 'https://api.sparkecrm.com/lead/'
  const postOptions = {
    method: 'POST',
    url: cakePostEndpointUrl,
    body: cakePostData,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return await fetch(cakePostEndpointUrl, postOptions)
    .then(res => {
      return res.json();
    });
}
