import {h, render} from 'preact';
import {FlowButton} from './flowButton.component';
import {ButtonLayout} from '../containers/buttonLayout.container';
import {ViewHeadline} from './viewHeadline.component';
import {StepCounter} from './stepCounter.component';

export function EstMedicalBills() {
  const pageHeader = {
    headline: 'What are your estimated medical bills?'
  };

  const progressChild = (
    <StepCounter
      currentStep={7}
      totalSteps={11}/>
  );

  const headlineChild = (
    <ViewHeadline {...pageHeader} />
  );

  const modelProp = 'estMedicalBills';
  const nextUrl = 'retained-attorney';

  const btnData = [
    {
      to: nextUrl,
      modelVal: 'None',
      modelProp,
      buttonText: 'None',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 9999,
      modelProp,
      buttonText: 'Under $10,000',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 25000,
      modelProp,
      buttonText: '$10,000 - $25,000',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 50000,
      modelProp,
      buttonText: '$25,000 - $50,000',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 100000,
      modelProp,
      buttonText: '$50,000 - $100,000',
      component: FlowButton
    },
    {
      to: nextUrl,
      modelVal: 100001,
      modelProp,
      buttonText: '$100,000 or more',
      component: FlowButton
    }
  ];

  return (
    <ButtonLayout
      headlineChild={headlineChild}
      progressChild={progressChild}
      btnData={btnData}/>
  );
}
