const privacyModalOpenBtn = document.querySelectorAll('a[data-target="#privacy-modal"]');
const modals = document.querySelectorAll('.modal-wrapper');

function openModal() {
  document.querySelector('body').classList.add('modal-open');
  document.querySelector('.modal').classList.add('show');
}

function closeModal() {
  document.querySelector('body').classList.remove('modal-open');
  document.querySelector('.modal').classList.remove('show');
}

export function initializeModals() {
  console.log(privacyModalOpenBtn);

  privacyModalOpenBtn.forEach(btn => {
    btn.addEventListener('click', ev => {
      openModal();
    });
  });

  modals.forEach(modal => {
    modal.addEventListener('click', ev => {
      if (ev.target && ev.target.matches('button.btn-close')) {
        closeModal();
      }
    })
  })
}
