import { h, render } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { SubmitButton } from './submitButton.component';
import { GlobalContext } from '../GlobalState';
import { phoneFormatter } from '../utility/phoneFormatter.utility';
import { CSSTransition } from 'react-transition-group';

export function Contact() {
  const { state } = useContext(GlobalContext);

  const initialFormState = {
    firstName: state.firstName || '',
    lastName: state.lastName || '',
    phone: state.phone || '',
    address: state.address || '',
    zip: state.zip,
    email: state.email || ''
  }

  const [verifyInfoFormData, setFormData] = useState(initialFormState);

  useEffect(() => {
    const addressFieldNames = Object.keys(initialFormState);

    addressFieldNames.forEach(fieldName => {
      const field = document.querySelector('#' + fieldName);
      const triggerInput = new Event(field, { bubbles: true });
      if (state[fieldName]) {
        field.value = state[fieldName];
        field.dispatchEvent(triggerInput);
      }
    })
  }, []);

  const onChangeHandler = (ev) => {
    console.log('change', ev.target.name);
    const formUserInput = {};

    if ((ev.target.name === 'phone') || (ev.target.name === 'altPhone')) {
      formUserInput[ev.target.name] = phoneFormatter(ev.target.value);
      ev.target.value = formUserInput[ev.target.name];
    } else {
      formUserInput[ev.target.name] = ev.target.value
    }

    setFormData({
      ...verifyInfoFormData,
      ...formUserInput
    });
  }

  const onFocusHandler = (ev) => {
    const errorFields = document.querySelectorAll('.is-invalid');

    errorFields.forEach(field => {
      field.classList.remove('is-invalid');
    })
  }

  return (
    <div className="view-bg">
      <CSSTransition
        in={true}
        appear
        enter
        exit
        timeout={0}
        classNames={'flow-view'}>

        <div className="col-12 col-sm-10 offset-sm-1">
          <div className="view-header">
            <h3>Last Step - How do we contact you?</h3>
          </div>

          <form id="contact" action="">
            <div className="form-group">
              <label className={'form-label'} htmlFor="firstName">First Name</label>
              <input
                id={'firstName'}
                name={'firstName'}
                type="text"
                className="form-control"
                placeholder={'First Name'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="form-group">
              <label className={'form-label'} htmlFor="lastName">Last Name</label>
              <input
                id={'lastName'}
                name={'lastName'}
                type="text"
                className="form-control"
                placeholder={'Last Name'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="form-group mb-4">
              <label className={'form-label'} htmlFor="phone">Phone</label>
              <input
                id={'phone'}
                name={'phone'}
                type="text"
                className="form-control"
                placeholder={'Phone'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="form-group mb-4">
              <label className={'form-label'} htmlFor="address">Address</label>
              <input
                id={'address'}
                name={'address'}
                type="text"
                className="form-control"
                placeholder={'ex. 123 Main Street'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="form-group">
              <label className={'form-label'} htmlFor="{'zip'}">Zip Code</label>
              <input
                id={'zip'}
                name={'zip'}
                type="email"
                className="form-control"
                placeholder={'12345'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="form-group">
              <label className={'form-label'} htmlFor="email">E-mail</label>
              <input
                id={'email'}
                name={'email'}
                type="email"
                className="form-control"
                placeholder={'E-mail'}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                required />

              <p className="invalid-feedback"></p>
            </div>

            <div className="col-12">
              <SubmitButton {...verifyInfoFormData} />

              <div className="row">
                <p className={'disclaimer-text'}>By submitting my information, I agree to the Terms & Conditions.  I consent to receive phone calls and/or text messages from The Injury Help Network or their attorney network at the number above in order to complete my evaluation, and I agree that these messages may be auto-dialed or pre-recorded.  I understand that consent is not a condition of purchase.  By completing this form, I am requesting and consenting to a follow-up communication from a lawyer.</p>
              </div>
            </div>
          </form>
        </div>
      </CSSTransition>
    </div>
  );
}
