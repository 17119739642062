import { h, render } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { ViewHeadline } from './viewHeadline.component';
import { StepCounter } from './stepCounter.component';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../GlobalState';
import { accidentStateFormSchema } from '../validators/accidentState.validator';

export function AccidentState() {
  const { updateModelProperty } = useContext(GlobalContext);
  const [accidentState, setAccidentState] = useState('');

  const pageHeader = {
    headline: 'In which state did this incident occur?'
  };

  const buttons = [
    {
      to: 'comments',
      buttonText: 'Next'
    }
  ];

  const onChangeHandler = (ev) => {
    setAccidentState(ev.target.value)
  }

  const onFocusHandler = (ev) => {
    // remove the error messages if they exist
    console.log(ev.target);
    const focusField = ev.target;
    focusField.classList.remove('is-invalid');
  }

  const onClickHandler = (ev) => {
    const isValidInput = async (schema) => {
      try {
        const valid = await schema.validateSync(
          {
            accidentState
          },
          { abortEarly: false }
        )

        if (valid) {
          console.log('VALID');
          // update model here if the form entries are valid
          updateModelProperty({
            accidentState
          });
        }
      } catch (error) {
        ev.preventDefault();
        // Debugging
        // console.log(Array.isArray(error.inner), '\n\n\nError Inner:', error.inner);

        // DISPLAY ERROR MESSAGES

        // using path, update add the error classes
        error.inner.forEach(err => {
          const errorFieldId = err.path;
          const errorField = document.getElementById(errorFieldId);
          const errorMessage = document.querySelector('#' + errorFieldId + ' ~ .invalid-feedback');

          // Debugging
          // console.log(err.path, err.message);
          // console.log(errorField, errorMessage, errorFieldId + ' ~ .invalid-feedback');

          errorField.classList.add('is-invalid');

          if (!errorMessage.innerHTML.length) {
            errorMessage.insertAdjacentHTML('beforeend', err.message)
          }
        });
      }
    }
    isValidInput(accidentStateFormSchema);
  }

  return (
    <div className="view-bg">
      <CSSTransition
        in={true}
        appear
        enter
        timeout={0}
        classNames={'flow-view'}>

        <div className="col-12 col-sm-10 offset-sm-1">
          <StepCounter
            currentStep={9}
            totalSteps={11}/>

          <ViewHeadline {...pageHeader} />

          <form className={'mb-4'} action="">
            <select className="form-select form-select-lg mb-3" name="accidentState" id="accidentState"
                    onChange={onChangeHandler} onFocus={onFocusHandler} required>
              <option value="">Please Select State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>

            <p className="invalid-feedback"></p>
          </form>

          <div className="next-btn-wrap col-12 col-sm-6 offset-sm-3 text-center">
            {
              buttons.map(button => <Link {...button} className={'flow-btn btn btn-outline-dark btn-default'} onClick={onClickHandler}>Next</Link>)
            }
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
